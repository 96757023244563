import styled from "styled-components"

const Contenedor = styled.div`
    width: 100%;
    height: 800px;
    background-color: rgb(193 165 137) ;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const Titulo = styled.h3`
    text-align: center;
    padding-top: 30px;
    font-size: 2rem;
    font-family: 'Quicksand', sans-serif;

    @media (min-width:20.00em) {     
   
  }
  @media (min-width:37.500em) {
   
    
  }
  @media(min-width:44rem) {
  
 
         
  }

@media(min-width:56rem) {
   

  }

  @media(min-width:61.93em) {
  
  } 
`

const Parrafo = styled.p`
    margin: 30px auto;
    width: 80%;
    font-size: 1.1rem;
    text-align: center;
    line-height: 25px;
    font-family: 'Quicksand', sans-serif;   
    line-height: 30px;
`
const SubTitulo = styled.h3`
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    font-family: 'Quicksand', sans-serif;
`

const Comodidades = () => {

    return (<>

        <Contenedor>
            <Titulo>Comodidades</Titulo>
            <Parrafo>En La Celina, encontrarás las siguientes comodidades: aire acondicionado para mantener un clima agradable en cualquier época del año, conexión a Internet para estar siempre conectado y acceso a Netflix para disfrutar de tus películas y series favoritas. Además, podrás hacer uso del tanque australiano, ideal para refrescarse en los días calurosos, y de la parrilla, perfecta para disfrutar de deliciosas comidas al aire libre."</Parrafo>
            <SubTitulo>¡ Con acceso al Quincho !</SubTitulo>
        </Contenedor>

    </>)

}

export default Comodidades;