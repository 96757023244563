import styled from "styled-components"
import { FaWhatsapp } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
const Contenedor = styled.div`
    width: 100%;
    background: rgb(51,83,65);
    height: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
`
const Titulo = styled.h3`
    text-align: center;
    padding-top: 30px;
    font-size: 2rem;
    font-family: 'Quicksand', sans-serif;
`
const SubTitulo = styled.h3`
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 1.2rem;
    font-family: 'Quicksand', sans-serif;
`
const Parrafo = styled.p`
    margin: 0 auto;
    width: 80%;
    font-size: 1.1rem;
    text-align: center;
    line-height: 25px;
    font-family: 'Quicksand', sans-serif;   
    line-height: 30px;
`


const StyledLink = styled.a`
    cursor: pointer;
    font-size: 1.2rem;
    color: #36bb32;
    font-weight: 800;
    :hover{
        transition: all 1s ease;
        text-decoration: underline;
    }

`
const StyledInstagramIcon = styled(FaInstagram)`
  font-size: 3rem;
  color: #E1306C;
  cursor: pointer;
  transition: all 0.2s ease;
  :hover{
    transform: scale(1.1);
  }
`;

const StyledWhatsappIcon = styled(FaWhatsapp)`
  font-size: 3rem;
  color: green;
  cursor: pointer;
  transition: all 0.2s ease;
  :hover{
    transform: scale(1.1);
  }
  
`
const ContenedorSocial = styled.div`
    margin-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 50px;
   
    
`

const Reservas = () => {


    return (<>
        <Contenedor>
            <Titulo>Reserva y contacto</Titulo>
            <SubTitulo>¡Haz tu reserva ahora!</SubTitulo>
            <Parrafo>Si estás interesado en pasar unos días en La Celina, no dudes en reservar. Para realizar la reserva o hacer consultas adicionales, contactanos por <StyledLink href="https://api.whatsapp.com/send?phone=+5492215671338&text=Hola!%20Quisiera%20obtener%20más%20información%20sobre%20La%20Celina.%20Muchas%20gracias!
" target="_blank"> WhatsApp</StyledLink>. Te responderemos a la brevedad posible. ¡Esperamos verte pronto!</Parrafo>
            <ContenedorSocial>
                <a href="https://api.whatsapp.com/send?phone=+5492215671338&text=Hola!%20Quisiera%20obtener%20más%20información%20sobre%20La%20Celina.%20Muchas%20gracias!
" target="_blank"> <StyledWhatsappIcon /> </a>
                <a href="https://www.instagram.com/casadecampolacelina/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank"> <StyledInstagramIcon /> </a>
            </ContenedorSocial>
        </Contenedor>



    </>)
}

export default Reservas;