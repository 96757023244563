import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';

import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import Slider1 from "../../Assets/Horizontal/SliderH1.jpeg"
import Slider2 from "../../Assets/Horizontal/SliderH2.jpeg"
import Slider3 from "../../Assets/Horizontal/SliderH3.jpeg"

import Slider5 from "../../Assets/Horizontal/SliderH5.jpeg"
import Slider6 from "../../Assets/Horizontal/SliderH6.jpeg"
import Slider7 from "../../Assets/Horizontal/SliderH7.jpeg"

const Imagen = styled.img`
    width: 100%;
    cursor: pointer;
`



const SliderHorizontal = () => {

    return (<>


        <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{
                el: '.pagination',
                clickable: true
            }}

        >
            <SwiperSlide><Imagen src={Slider1} alt="Futbol Inflable" loading='lazy' /></SwiperSlide>
            <SwiperSlide><Imagen src={Slider2} alt="Punteria" loading='lazy' /></SwiperSlide>
            <SwiperSlide><Imagen src={Slider3} alt="Gacebos" loading='lazy' /></SwiperSlide>
            <SwiperSlide><Imagen src={Slider5} alt="Tanque Australiano" loading='lazy' /></SwiperSlide>
            <SwiperSlide><Imagen src={Slider6} alt="Panorama" loading='lazy' /></SwiperSlide>
            <SwiperSlide><Imagen src={Slider7} alt="La Celina" loading='lazy' /></SwiperSlide>

        </Swiper>




    </>)
}


export default SliderHorizontal