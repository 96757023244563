import styled from "styled-components"
import "../Assets/fonts.css"
import Slider from "./pequeños/Slider"

const Contenedor = styled.section`
    background-color: rgb(193 165 137) ;
    width: 100%;
    height: 650px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

   @media (min-width:20.00em) {     
     height: auto;
  }
  
  @media (min-width:37.500em) {
  
    
  }
  @media(min-width:44rem) {
   
          
  }
  @media(min-width:56rem) {
    height: 650px ;
    
      
  }

  @media(min-width:61.93em) {
   
  } 
  @media(min-width:80.00em) {
    
    
  } 

`
const ContenedorTexto = styled.article`
    width: 50%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
     @media (min-width:20.00em) {     
     width: 100%;
  }
  
  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:44rem) {
    
          
  }
  @media(min-width:56rem) {
    width: 50%;
    
    
      
  }

  @media(min-width:61.93em) {
   
  } 
  @media(min-width:80.00em) {
    width: 60%;
    
  } 
`
const Titulo = styled.h3`
    text-align: center;
    padding: 30px;
    font-size: 2rem;
    font-family: 'Quicksand', sans-serif;
    
`
const Parrafo = styled.p`
    margin: 0 auto;
    width: 80%;
    font-size: 1.1rem;
    text-align: center;
    line-height: 30px;
    font-family: 'Quicksand', sans-serif;
`

const ContenedorSlider = styled.div`
    width: 50%;
    height: 100%;
    @media (min-width:20.00em) {     
     width: 100%;
    
  }
  
  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:44rem) {
    
          
  }
  @media(min-width:56rem) {
    width: 50%;
    
      
  }

  @media(min-width:61.93em) {
    
  } 
  @media(min-width:80.00em) {
    width: 40%;
   
  } 
`


const Descripcion = () => {

  return (<>
    <Contenedor>
      <ContenedorTexto>
        <Titulo>
          Bienvenidos a La Celina
        </Titulo>
        <Parrafo>Una encantadora casa de campo ubicada a solo 15 minutos de la ciudad de La Plata. Rodeada de amplios espacios verdes, ofrece una cómoda capacidad de alojamiento para 4 personas. Con comodidades como aire acondicionado, conexión a Internet y acceso a Netflix, encontrarás todo lo necesario para una estadía relajante. Además, puedes disfrutar del tanque australiano y la parrilla del quincho, creando un ambiente tranquilo y perfecto para desconectarse</Parrafo>

      </ContenedorTexto>
      <ContenedorSlider>
        <Slider />
      </ContenedorSlider>
    </Contenedor>

  </>)
}

export default Descripcion