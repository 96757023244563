import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';

import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import slider1 from "../../Assets/slider1.jpg"
import slider2 from "../../Assets/slider2.jpg"
import slider3 from "../../Assets/slider3.jpg"
import slider4 from "../../Assets/slider4.jpg"
import slider5 from "../../Assets/slider5.jpg"
import slider6 from "../../Assets/slider6.jpg"
import slider7 from "../../Assets/slider7.jpg"


const Imagen = styled.img`
    width: 100%;
  @media (min-width:20.00em) {     
     
  }
  
  @media (min-width:37.500em) {
  
    
  }
  @media(min-width:44rem) {
   
          
  }
  @media(min-width:56rem) {
    width: 100%;
    height: 650px;
    cursor: pointer;
    display: inline-block;
    
  }

  @media(min-width:61.93em) {
   
  } 
  @media(min-width:80.00em) {
    
    
  } 
`


const SwiperContainer = styled(Swiper)`
  border-bottom-left-radius: 1rem;  

`;




const Slider = () => {
  return (
    <>
      <SwiperContainer
        modules={[Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{
          clickable: true
        }}

      >
        <SwiperSlide><Imagen src={slider1} alt="La Celina" loading='lazy' /></SwiperSlide>
        <SwiperSlide><Imagen src={slider2} alt="Cocina" loading='lazy' /></SwiperSlide>
        <SwiperSlide><Imagen src={slider3} alt="Comedor" loading='lazy' /></SwiperSlide>
        <SwiperSlide><Imagen src={slider4} alt="Habitacion" loading='lazy' /></SwiperSlide>
        <SwiperSlide><Imagen src={slider5} alt="Baño1" loading='lazy' /></SwiperSlide>
        <SwiperSlide><Imagen src={slider6} alt="Baño2" loading='lazy' /></SwiperSlide>
        <SwiperSlide><Imagen src={slider7} alt="La Celina" loading='lazy' /></SwiperSlide>

      </SwiperContainer>

    </>
  );
};

export default Slider;