import styled from "styled-components"
import Navbar from "./pequeños/Navbar"
import Portada from "./Portada"
import Descripcion from "./Descripcion"
import Detalles from "./Detalles"
import Comodidades from "./Comodidades"
import Reservas from "./Reservas"
const Contenedor = styled.header`


`
const Footer = styled.footer`
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    line-height: 30px;
`



const Home = () => {


    return (<>
        <Contenedor>
            <Navbar />
            <Portada />
        </Contenedor>
        <Descripcion id="Bienvenidos" />
        <Detalles id="Detalles" />
        <Comodidades id="Comodidades" />
        <Reservas id="Reservas" />
        <Footer>
            © 2023 La Celina - Todos los derechos reservados
        </Footer>

    </>)
}

export default Home