import styled from "styled-components";
import "../Assets/fonts.css"
import SliderHorizontal from "./pequeños/SliderHorizontal";


const Contenedor = styled.div`
    width: 100%;
    background: rgb(51,83,65);
    margin-bottom: -5px;

     @media (min-width:20.00em) {     
     margin-top: -3px;
  }
  
  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:44rem) {
    
          
  }
  @media(min-width:56rem) {
    margin-top: 0px;
    
    
      
  }

  @media(min-width:61.93em) {
   
  } 
  @media(min-width:80.00em) {
    
    
  } 
`
const Titulo = styled.h3`
    text-align: center;
    padding-top: 30px;
    font-size: 2rem;
    font-family: 'Quicksand', sans-serif;
`
const SubTitulo = styled.h3`
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 1.2rem;
    font-family: 'Quicksand', sans-serif;
`
const ContenedorDetalles = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
`

const Parrafo = styled.p`
    margin: 0 auto;
    width: 80%;
    font-size: 1.1rem;
    text-align: center;
    line-height: 25px;
    font-family: 'Quicksand', sans-serif;   
    line-height: 30px;
`
const Span2 = styled.p`
    align-items: center;
    text-align: center;
    margin-top: 20px;
    font-weight: 800;
    margin: 10px auto;
    width: 80%;
    font-size: 1.1rem;
    text-align: center;
    line-height: 25px;
    font-family: 'Quicksand', sans-serif;   
`

const Span = styled.span`
    font-weight: 800;
`

const StyledLink = styled.a`
    cursor: pointer;
    font-size: 1.2rem;
    color: #d3d3d3;
    font-weight: 800;
    :hover{
        transition: all 1s ease;
        text-decoration: underline;
    }

`

const ContenedorSlider = styled.div`
    width: 100%;
    margin-top: 60px;
   
`


const Detalles = () => {

    return (<>

        <Contenedor>
            <ContenedorDetalles>
                <Titulo>Detalles de la estadía</Titulo>
                <SubTitulo>Reserva, horarios y condiciones</SubTitulo>
                <Parrafo>La Celina se alquila <Span>por día, con entrada a partir de las 11:00 a.m. y salida a las 10:00 a.m. del día siguiente.</Span> Para concretar la reserva, se requiere un depósito como seña. Si tienes alguna duda o consulta, no dudes en <StyledLink>contactarnos</StyledLink>.</Parrafo> <Span2>¡Gracias por considerar La Celina para tu escapada! </Span2>
            </ContenedorDetalles>
            <ContenedorSlider>
                <SliderHorizontal />
            </ContenedorSlider>
        </Contenedor >

    </>)
}

export default Detalles;