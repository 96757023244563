import styled from "styled-components";
import cabaña from "../Assets/cabaña.png"
import "../Assets/fonts.css"




const Contenedor = styled.div`
    width: 100%;
    height: 80vh;
    position: relative;
    background-image: url(${cabaña});
    max-height: 800px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

  @media (min-width:20.00em) {     
     background-size: cover;
     background-position: center;
  }
   @media (min-width:25.00em) {     
     
    
  }

  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:44rem) {
    
          
  }
  @media(min-width:56rem) {
    
    
      
  }

  @media(min-width:61.93em) {
    
  } 
`
const ContenedorTitulo = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.2);
    font-family: 'Quicksand', sans-serif;
    border: 2px solid white;
    padding: 50px;
    text-transform: uppercase;
    transition: all 1s ease;
    border-radius: 0.2rem;
`


const Pantalla = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    :hover{
        ${ContenedorTitulo}{
            background-color: rgba(255, 255, 255, 0.9);
        }

    }


    @media (min-width:20.00em) {     
        display: none;
    
  }
  @media (min-width:37.500em) {
    display: block;
    
  }
  @media(min-width:44rem) {
   

  }
  @media(min-width:61.93em) {

  }

`


const Titulo = styled.h1`
    text-align: center;
    margin-bottom: 20px;
    font-size: 3.2rem;
    letter-spacing: 10px;
`
const SubTitulo = styled.h3`
    text-align: center;
    font-size: 2.1rem;
    letter-spacing: 3px;
`

const Portada = () => {

    return (<>
        <Contenedor>
            <Pantalla>
                <ContenedorTitulo>
                    <Titulo>
                        La Celina
                    </Titulo>
                    <SubTitulo>
                        Casa de Campo
                    </SubTitulo>
                </ContenedorTitulo>
            </Pantalla>
        </Contenedor>
    </>)
}

export default Portada;

