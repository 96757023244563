import styled, { css } from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import "../../Assets/fonts.css"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";





const StyledLink = styled.a`
    font-family: 'Quicksand', sans-serif;
    color: white;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    transition: all .3s;
    letter-spacing: 4px;
    color: ${({ active }) => (active ? "#40285367" : "white")};
    text-shadow: ${({ active }) => (active ? "text-shadow: 1px 1px 1px #000000;" : "")};

    :hover{

        color: #40285367;
    }

     @media (min-width:20.00em) {     
    
    
  }
  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:56rem) {
    

  }
  @media(min-width:61.93em) {

  }
`
const StyledTitulos = styled.h2`
    white-space: nowrap;
    color: white;
    text-align: center;
    font-size: 2.5rem;
    margin-left: 10%;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
    padding: 10px;
    letter-spacing: 3px;
    font-weight: 300;
    
  @media (min-width:20.00em) {     
      margin-left: 1rem;
     
  }

  @media (min-width:37.500em) {
     margin-left: 10%;
   
  
 
    
    
  }
  @media(min-width:44rem) {
   
   
         
  }
  @media(min-width:61.93em) {
    
  } 
    
`
const Contenedor = styled.nav`
    background-color: rgb(51, 83, 65);
    box-shadow: inset 0px -20px 20px -3px rgba(255,255,255,.1);
    width:100%;
    height: 100px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    justify-content: space-between;
    align-items: center;
    text-align: center;
    display: flex;
    transition: all 0.5s ease-in-out;
   ${(props) =>
    props.isActive &&
    css`
      ul {
        height: 100vh;
      }
    `}
   ${(props) =>
    props.isActive &&
    css`
      ul {
        height: 100vh;
      }
    `}


    @media (min-width:20.00em) {     
  
    
  }
  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:44rem) {
   

  }
  @media(min-width:61.93em) {

  }


`
const SeccionesUl = styled.ul`
    position: fixed;
    width: 100%;
    height: 0vh;
    top: 101px;
    background-color: rgba(0,0,0,.95);
    transition: all .3s;
    margin: 0;
    padding: 0;
    z-index: 10;
 @media (min-width:20.00em) {     
    
  }
  @media (min-width:37.500em) {
   
    
  }
  @media(min-width:44rem) {
  
 
         
  }

@media(min-width:56rem) {
      position: relative;
   height: 100px;
   top: 0;
   transition: none;
   text-align: right;
   margin-right: 10px;
   background-color: transparent;
    

  }

  @media(min-width:61.93em) {
  
  } 
   
`
const BarBtn = styled.label`
  margin-right:10%;
  font-size: 1.8rem;
  color: white;
  background-color: rgba(255,255,255,.1);
  padding: 10px;
  border-radius: 10%;
  :hover{
    background-color: transparent;
    transform: scale(1.2);
    transition: all .4s ease-in-out;
    border: 1px solid wheat;
  }
   
  @media (min-width:20.00em) {     
      
    
  }
   @media (min-width:25.00em) {     
     
    
  }

  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:44rem) {
    
          
  }
  @media(min-width:56rem) {
    
    
  display: none;       
  }

  @media(min-width:61.93em) {
    
  } 
`


const StyledLi = styled.li`
    z-index: 10;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: block;
    line-height: 30px;
    margin: 50px 0;
    transition: all .5s;
    ${(props) =>
    !props.isActive &&
    css`
            display: none;
        `
  }

  @media (min-width:20.00em) {       
    
  }
  @media (min-width:37.500em) {
    
    
  }
  @media(min-width:44rem) {
  
    
         
  }
  @media (min-width:56rem) {
     display: inline-block;
   line-height:100px;
   margin:0 10px;
   transition:none;
    
  }
  @media(min-width:61.93em) {
    margin:0 20px;
    
  
  } 
`




const Navbar = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("");
  const cambiaRuta = (path) => {

    navigate(path);
  }

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  React.useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActiveSection("inicio");
    } else if (path === "/fotografias") {
      setActiveSection("fotografias");
    } else if (path === "/videos") {
      setActiveSection("videos");
    } else if (path === "/contacto") {
      setActiveSection("contacto");
    }
  }, [location]);


  return (<>
    <Contenedor isActive={isActive}>

      <StyledTitulos>La Celina</StyledTitulos>


      <BarBtn className="bar-btn" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </BarBtn>

      <SeccionesUl>
        <StyledLi isActive={isActive}><StyledLink onClick={() => cambiaRuta('/')} active={activeSection === "inicio"} > Inicio </StyledLink></StyledLi>
        <StyledLi isActive={isActive}><StyledLink onClick={() => cambiaRuta('/Detalles')} active={activeSection === "Detalles"}>Detalles</StyledLink></StyledLi>
        <StyledLi isActive={isActive}><StyledLink onClick={() => cambiaRuta('/Comodidades')} active={activeSection === "Comodidades"}>Comodidades</StyledLink></StyledLi>
        <StyledLi isActive={isActive}> <StyledLink onClick={() => cambiaRuta('/Reservas')} active={activeSection === "Reservas"}>Reservas</StyledLink></StyledLi>
      </SeccionesUl>
    </Contenedor>

  </>)
}

export default Navbar;