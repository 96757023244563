import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Detalles from './Components/Detalles';
import Comodidades from './Components/Comodidades';
import Reservas from './Components/Reservas';
import Navbar from './Components/pequeños/Navbar';

function App() {
  return (

    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Detalles' element={<>
            <Navbar />
            <Detalles />
          </>} />
          <Route path='/Comodidades' element={
            <>
              <Navbar />
              <Comodidades />

            </>
          } />
          <Route path='/Reservas' element={
            <>
              <Navbar />
              <Reservas />
            </>
          } />
          <Route path='*' element={<Navigate to={'/'} replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
